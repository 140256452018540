import React from 'react';
import { useTranslation } from 'react-i18next';

import { Loader } from '@shared/ui/loader/Loader';
import { LoaderWrapper } from '@shared/ui/loader/LoaderWrapper';
import { useCertificate } from '@shared/hooks/useCertificate';

/**
 * Backward compability with old site
 *
 * TODO: change
 */
const CertificatePage = ({ id }: { id: string }) => {
  const { t } = useTranslation();

  const { error, isLoading } = useCertificate(id);

  if (error) {
    return (
      <div className="min-h-[200px]">
        <div className="absolute px-5 py-5 text-center flex flex-col grow w-full h-full items-center justify-center">
          <div>{t('errors.bad-certificate')}.</div>
        </div>
      </div>
    );
  }

  if (isLoading) {
    return (
      <LoaderWrapper className="min-h-[200px]">
        <Loader />
      </LoaderWrapper>
    );
  }

  return null;
};

export default CertificatePage;
